.Banner2-wrapper {
    background-color: #f6f4f28a;
}

.Banner2-wrapper .subtitle {
    color: #dba860;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.4em;
    line-height: 1.5em;
}

.Banner2-wrapper .title {
    font-size: 3em;
    font-family: 'Playfair Display', serif;
    line-height: 1.32em;
    font-weight: bold;
    position: relative;
}

.Banner2-wrapper .title::before {
    content: "";
    width: 89px;
    max-width: 50%;
    height: 2px;
    background-color: #dba860;
    position: absolute;
    bottom: 0;
}

.Banner2-wrapper .background {
    background-size: cover;
    background-position: center;
    min-width: auto;
}