.carousel .item div {
    background-position: center;
    background-size: cover;
    min-height: 600px;
    min-width: auto;
}

.contact-banner {
    background-color: #4b868e;
    width: 100%;
    box-sizing: border-box;
}

.contact-banner-title {
    color: white;
}

.contact-banner-desc {
    color: white;
}

.home-wrapper .imageBanner {
    height: 300px;
    background-size: cover;
    background-position: center;
}