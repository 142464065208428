.Banner3-wrapper {
    background-color: #f6f4f2b9;
    background-position: center;
    background-position-y: 30%;
}

.Banner3-wrapper .subtitle {
    color: #dba860;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.4em;
    line-height: 1.5em;
}

.pastors {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.Banner3-wrapper .title {
    line-height: 1.32em;
    position: relative;
}

.Banner3-wrapper .title::before {
    content: "";
    width: 89px;
    max-width: 50%;
    height: 2px;
    background-color: #dba860;
    position: absolute;
    bottom: 0;
}