.welcomeMessage h6 {
    font-size: 0.929rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.4em;
    line-height: 1.5em;
    color: #dba860;
}

.underlineTitle {
    line-height: 1.32em;
    position: relative;
}

.underlineTitle::before {
    content: "";
    width: 89px;
    margin: auto;
    max-width: 50%;
    height: 2px;
    background-color: #dba860;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0px);
}

.welcomeMessage div {
    text-align: center;
    max-width: 75%;
}