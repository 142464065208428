@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Oswald&family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#hidden {
    display: none;
}

.navbar {
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
}

.navbar ul {
    display: flex;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    height: 100%;
    align-items: center;
}

.navbar ul li {
    list-style-type: none;
    padding: 10px 20px;
}

.navbar ul figure {
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 10px;
            margin-inline-start: 10px;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    cursor: pointer;
}
.footer-wrapper li {
    text-decoration: none;
    list-style-type: none;
}

.footer-wrapper {
    background-color: #f6f4f2;
}

@media screen and (min-width: 480px) {
    .footer-wrapper .left-side {
        text-align: left !important;
    }
}

.carousel .item div {
    background-position: center;
    background-size: cover;
    min-height: 600px;
    min-width: auto;
}

.contact-banner {
    background-color: #4b868e;
    width: 100%;
    box-sizing: border-box;
}

.contact-banner-title {
    color: white;
}

.contact-banner-desc {
    color: white;
}

.home-wrapper .imageBanner {
    height: 300px;
    background-size: cover;
    background-position: center;
}
.welcomeMessage h6 {
    font-size: 0.929rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.4em;
    line-height: 1.5em;
    color: #dba860;
}

.underlineTitle {
    line-height: 1.32em;
    position: relative;
}

.underlineTitle::before {
    content: "";
    width: 89px;
    margin: auto;
    max-width: 50%;
    height: 2px;
    background-color: #dba860;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0px);
}

.welcomeMessage div {
    text-align: center;
    max-width: 75%;
}
.Banner1-wrapper {
    background-color: #f6f4f2b9;
}

.Banner1-wrapper .subtitle {
    color: #dba860;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.4em;
    line-height: 1.5em;
}

.pastors {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.Banner1-wrapper .title {
    line-height: 1.32em;
    position: relative;
}

.Banner1-wrapper .title::before {
    content: "";
    width: 89px;
    max-width: 50%;
    height: 2px;
    background-color: #dba860;
    position: absolute;
    bottom: 0;
}
.Banner2-wrapper {
    background-color: #f6f4f28a;
}

.Banner2-wrapper .subtitle {
    color: #dba860;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.4em;
    line-height: 1.5em;
}

.Banner2-wrapper .title {
    font-size: 3em;
    font-family: 'Playfair Display', serif;
    line-height: 1.32em;
    font-weight: bold;
    position: relative;
}

.Banner2-wrapper .title::before {
    content: "";
    width: 89px;
    max-width: 50%;
    height: 2px;
    background-color: #dba860;
    position: absolute;
    bottom: 0;
}

.Banner2-wrapper .background {
    background-size: cover;
    background-position: center;
    min-width: auto;
}
.Banner3-wrapper {
    background-color: #f6f4f2b9;
    background-position: center;
    background-position-y: 30%;
}

.Banner3-wrapper .subtitle {
    color: #dba860;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.4em;
    line-height: 1.5em;
}

.pastors {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.Banner3-wrapper .title {
    line-height: 1.32em;
    position: relative;
}

.Banner3-wrapper .title::before {
    content: "";
    width: 89px;
    max-width: 50%;
    height: 2px;
    background-color: #dba860;
    position: absolute;
    bottom: 0;
}
.Banner4-wrapper {
    background-color: #f6f4f2b9;
    background-position: center;
    background-position-y: 30%;
}

.Banner4-wrapper .welcomeTitle {
    font-size: 3rem;
    font-family: 'Playfair Display', serif;
}

.Banner4-wrapper .subtitle {
    color: #dba860;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.4em;
    line-height: 1.5em;
}

.Banner4-wrapper .title {
    line-height: 1.32em;
    position: relative;
}

.Banner4-wrapper .title::before {
    content: "";
    width: 89px;
    max-width: 50%;
    height: 2px;
    background-color: #dba860;
    position: absolute;
    bottom: 0;
}
.history-container {
    background-color: #4b868e;
}

.discipleship-wrapper .imageBanner {
    height: 300px;
    background-size: cover;
    background-position: center;
}

.discipleship-wrapper .divder {
    max-width: 50%;
}
.youth-wrapper .imageBanner {
    height: 300px;
    background-size: cover;
    background-position: center;
}
textarea, input { 
    outline: none;
    box-shadow: none;
    border: none;
    color: white;
};

input:focus, textarea:focus, select:focus{
    outline: none;
}

.btn:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
}

body {
    color: #4f4f4f !important;
    font-family: 'Montserrat', sans-serif !important;
    background-color: #f6f4f2;
    font-size: 13px !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif;
}

.decr-none {
    text-decoration: none;
    color: inherit !important;
}

.decr-none:hover {
    color: #dba860 !important;
    text-decoration: none !important;
}

.decr-none:focus {
    color: #dba860 !important;
    text-decoration: none !important;
}

.rounded-0 {
    border-width: 2px !important;
}

.bold-font {
    font-weight: bold;
}

.btn-gold {
    background-color: #dba860 !important;
    border-color: #dba860  !important;
    -webkit-appearance: none;
}

.btn-gold:hover {
    background-color: #b48a4f !important;
    border-color: #b48a4f  !important;
}

.nav-link {
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.nav-link:hover {
    color: #b48a4f  !important;
}

.dropdown-item {
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.dropdown-item:hover {
    color: #b48a4f  !important;
}

.no-border {
    outline: none;
    border: none;
    background-color: transparent !important;
}

.white-font {
    color: white !important;
}
